<script lang="ts">
  import { getContext, onMount, setContext } from "svelte";
  import { Router, Link, Route } from "svelte-routing";
  import { apiToken } from "./store.js";
  import Redirect from "./components/Redirect.svelte";

  const checkToken = async (token) => {
    const resp = await fetch(`${BASE_URL}/v1/auth/me`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return resp.ok;
  };

  const localStorageToken = localStorage.getItem("studious-api-key");
  if (localStorageToken) {
    if (checkToken(localStorageToken)) {
      apiToken.set(localStorageToken);
    }
  } else {
    const sessionStorageToken = sessionStorage.getItem("studious-api-key");
    if (sessionStorageToken) {
      if (checkToken(sessionStorageToken)) {
        apiToken.set(sessionStorageToken);
      }
    }
  }

  // Pages
  import Login from "./pages/Login.svelte";
  import Register from "./pages/Register.svelte";
  // import Redirect from "./components/Redirect.svelte";
  // import Dashboard from "./pages/Dashboard.svelte";
  import Privacy from "./pages/Privacy.svelte";
  // import Settings from "./pages/Settings.svelte";
</script>

<Router>
  <!-- <Route path="/privacy" component={Privacy} /> -->

  <!-- <Route path="/privacy" component={Privacy} /> -->

  {#if !$apiToken}
    <Route path="/register">
      <Register />
    </Route>
    <Route path="/privacy-policy">
      <Privacy />
    </Route>
    <Route path="/login">
      <Login />
    </Route>
    <Route>
      <Redirect to="/login" />
    </Route>
  {:else}
    <p>Hello friends</p>
  {/if}

  <!-- </Route> -->

  <!-- <Route path="/dashboard/*" component={Dashboard} />
            <Route path="/settings" component={Settings} />
            <Route>
                <Redirect to="/dashboard" />
            </Route> -->
</Router>

<!-- Styles -->
<style>
  :global(body) {
    background: #222831;
  }

  :global(.body-background) {
    background: #222831;
  }

  .login-holder {
    display: grid;
    height: 100%;
    align-self: center;
    justify-items: center;
  }

  h1,
  em {
    color: #ff3e00;
  }

  hr {
    height: 1px;
    border: none;
    background: rgb(195, 195, 195);
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>
