<script lang="ts">
  import { onMount, setContext } from "svelte";
  import { fade } from "svelte/transition";
  import { apiToken } from "../store.js";
  import LoginError from "../components/LoginError.svelte";
  import Logo from "../components/Animated/Logo.svelte";
  import { navigate } from "svelte-routing";

  let email = "";
  let name = "";
  let password = "";
  let dateOfBirth = "";
  let errorMessage = "";

  let monthlyMembership = true;

  let agreeToPrivacyPolicy = false;

  const errorMessageDisplay = (message) => {
    errorMessage = message;

    setTimeout(() => {
      errorMessage = "";
    }, 4000);
  };

  const login = async () => {
    const data = { email, password };
    console.log(`Remember ME: ${rememberMe}`);
    const resp = await fetch(`${BASE_URL}/v1/auth/login`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (resp.ok) {
      console.log("logged in!");
      const respObj = await resp.json();

      if (rememberMe) {
        localStorage.setItem("studious-api-key", respObj.token);
        apiToken.set(respObj.token);
        navigate("/");
      } else {
        sessionStorage.setItem("studious-api-key", respObj.token);
        apiToken.set(respObj.token);
        navigate("/");
      }
    } else if (resp.status === 404) {
      errorMessageDisplay(
        "Oops! It seems like we can't find you. Are you sure you've entered your email correctly?"
      );
    } else if (resp.status === 401) {
      errorMessageDisplay("The password you entered is incorrect. Try again?");
    } else {
      errorMessageDisplay(
        "Oops! Something went wrong, please try again later?"
      );
    }
  };

  const redirectLogin = () => {
    navigate("/login");
  };
  const redirectPrivacyPolicy = () => {
    navigate("/privacy-policy");
  };
</script>

<main>
  <!-- <img class="logo" src="/assets/Logo.svg" alt="Studious" /> -->
  <div
    class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full space-y-4">
      <div class="logo">
        <Logo condition={true} notAnimated={true} />
      </div>
      <p class="text-center text-sm text-gray-500">
        Register for an account or
        <a
          on:click={redirectLogin}
          class="font-medium text-gray-300 cursor-pointer"
        >
          Login if you're already a member.
        </a>
      </p>
      {#if errorMessage.length > 0}
        <div transition:fade>
          <LoginError message={errorMessage} />
        </div>
      {/if}
      <form
        on:submit|preventDefault={login}
        class="mt-2 space-y-4"
        novalidate
        p
      >
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="full-name" class="sr-only">Full Name</label>
            <input
              id="full-name"
              name="full-name"
              type="text"
              autocomplete="name"
              bind:value={name}
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm transition-all"
              placeholder="Full Name"
            />
          </div>
          <div>
            <label for="email-address" class="sr-only">Email Address</label>
            <input
              id="email-address"
              name="email"
              type="email"
              autocomplete="email"
              bind:value={email}
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm transition-all"
              placeholder="Email Address"
            />
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              bind:value={password}
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm transition-all"
              placeholder="Password"
            />
          </div>
          <div>
            <label for="date-of-birth" class="sr-only">Date of Birth</label>
            <input
              id="dob"
              name="date-of-birth"
              type="date"
              autocomplete="date-of-birth"
              bind:value={dateOfBirth}
              class={dateOfBirth.length > 0
                ? "appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm transition-all"
                : "appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-500 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm transition-all "}
              placeholder="Password"
            />
          </div>
        </div>
        <span
          class="relative z-0 inline-flex shadow-sm rounded-md w-full inline-grid grid-cols-2"
        >
          <button
            type="button"
            on:click={() => {
              monthlyMembership = true;
            }}
            class={monthlyMembership
              ? "relative inline-flex items-center px-4 py-2 rounded-l-md rounded-r-none border border-gray-300 bg-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-400 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 justify-center"
              : "relative inline-flex items-center px-4 py-2 rounded-l-md rounded-r-none border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 justify-center"}
          >
            £1 per month
          </button>
          <button
            on:click={() => {
              monthlyMembership = false;
            }}
            type="button"
            class={!monthlyMembership
              ? "-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md rounded-l-none border border-gray-300 bg-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-400 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 justify-center"
              : "-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md rounded-l-none border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 justify-center"}
          >
            <strike
              class={!monthlyMembership ? "text-gray-500" : "text-gray-300"}
              >£12</strike
            >
            &nbsp;£10 per year
            <span class={!monthlyMembership ? "text-red-400" : "text-red-300"}
              >&nbsp;(16% off)</span
            >
          </button>
        </span>
        <div class="flex">
          <input
            id="agree"
            name="agree"
            type="checkbox"
            bind:checked={agreeToPrivacyPolicy}
            class="h-4 w-4 text-gray-600 focus:ring-gray-500 border-gray-300 rounded transition-all"
          />
          <label for="agree" class="text-center text-xs ml-2 text-gray-500">
            I have read and agree with the
            <a
              on:click={redirectPrivacyPolicy}
              class="text-gray-300 cursor-pointer"
            >
              Privacy Policy.
            </a>
          </label>
        </div>
        <div>
          <button
            type="submit"
            class={!agreeToPrivacyPolicy
              ? "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md bg-gray-600  transition-all cursor-default"
              : "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 transition-all"}
            disabled={!agreeToPrivacyPolicy}
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
            </span>
            Continue
          </button>
        </div>
      </form>
      <p class="text-xs text-gray-500">
        By continuing, you will be redirected to our payment partner to enter
        your payment details. After completion, you will get access to a
        one-week free trial. Don't worry, you can cancel at any time!
      </p>
    </div>
  </div>
</main>

<style>
  main {
    text-align: center;
    margin: auto;
  }

  .logo {
    display: block;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
  }
</style>
