<script>
  import { draw, fade } from "svelte/transition";
  import { quintOut } from "svelte/easing";

  export let condition;
  export let notAnimated;
</script>

{#if notAnimated}
  <svg viewBox="0 0 475 146" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M70.1656 118.17C64.0816 118.248 58.8556 117.234 54.4876
            115.128C50.1196 113.022 45.9076 109.941 41.8516 105.885C41.6956
            105.729 41.5396 105.534 41.3836 105.3C41.2276 104.988 41.1496
            104.598 41.1496 104.13C41.1496 103.506 41.4226 102.921 41.9686
            102.375C42.5146 101.751 43.0996 101.439 43.7236 101.439C44.4256
            101.439 45.0886 101.79 45.7126 102.492C48.8326 106.08 52.4596 108.81
            56.5936 110.682C60.8056 112.554 65.1736 113.49 69.6976
            113.49C73.6756 113.49 77.2246 112.866 80.3446 111.618C83.4646
            110.292 85.9216 108.459 87.7156 106.119C89.5096 103.701 90.4066
            100.893 90.4066 97.695C90.3286 93.717 89.2366 90.519 87.1306
            88.101C85.0246 85.683 82.2946 83.694 78.9406 82.134C75.6646 80.574
            72.1936 79.131 68.5276 77.805C65.6416 76.713 62.7946 75.582 59.9866
            74.412C57.1786 73.242 54.6436 71.838 52.3816 70.2C50.1196 68.484
            48.3256 66.378 46.9996 63.882C45.6736 61.308 45.0106 58.149 45.0106
            54.405C45.0106 50.583 46.0246 47.151 48.0526 44.109C50.1586 40.989
            53.1226 38.532 56.9446 36.738C60.8446 34.866 65.4466 33.93 70.7506
            33.93C75.1966 33.93 79.5256 34.749 83.7376 36.387C87.9496 37.947
            91.2646 40.326 93.6826 43.524C94.4626 44.616 94.8526 45.435 94.8526
            45.981C94.8526 46.527 94.5406 47.073 93.9166 47.619C93.3706 48.165
            92.7466 48.438 92.0446 48.438C91.4206 48.438 90.9136 48.204 90.5236
            47.736C89.1196 45.864 87.4036 44.265 85.3756 42.939C83.4256 41.535
            81.2026 40.482 78.7066 39.78C76.2106 39 73.5586 38.61 70.7506
            38.61C66.9286 38.61 63.4576 39.234 60.3376 40.482C57.2176 41.73
            54.7216 43.524 52.8496 45.864C50.9776 48.126 50.0416 50.934 50.0416
            54.288C50.0416 57.876 51.0556 60.84 53.0836 63.18C55.1896 65.442
            57.8416 67.353 61.0396 68.913C64.2376 70.395 67.5136 71.76 70.8676
            73.008C73.8316 74.022 76.7566 75.153 79.6426 76.401C82.6066 77.571
            85.2586 79.053 87.5986 80.847C90.0166 82.641 91.9276 84.903 93.3316
            87.633C94.7356 90.363 95.4376 93.795 95.4376 97.929C95.4376 101.517
            94.3846 104.832 92.2786 107.874C90.2506 110.916 87.3646 113.373
            83.6206 115.245C79.8766 117.117 75.3916 118.092 70.1656
            118.17ZM103.956 58.149H131.1C131.724 58.149 132.231 58.383 132.621
            58.851C133.089 59.319 133.323 59.826 133.323 60.372C133.323 60.996
            133.089 61.542 132.621 62.01C132.231 62.4 131.724 62.595 131.1
            62.595H103.956C103.41 62.595 102.903 62.361 102.435 61.893C101.967
            61.425 101.733 60.918 101.733 60.372C101.733 59.748 101.967 59.241
            102.435 58.851C102.903 58.383 103.41 58.149 103.956 58.149ZM115.89
            40.95C116.592 40.95 117.138 41.184 117.528 41.652C117.996 42.12
            118.23 42.666 118.23 43.29V104.247C118.23 106.821 118.542 108.732
            119.166 109.98C119.868 111.15 120.726 111.93 121.74 112.32C122.754
            112.632 123.729 112.788 124.665 112.788C125.211 112.788 125.679
            112.71 126.069 112.554C126.459 112.398 126.927 112.32 127.473
            112.32C128.019 112.32 128.487 112.554 128.877 113.022C129.267
            113.412 129.462 113.88 129.462 114.426C129.462 115.128 128.994
            115.752 128.058 116.298C127.2 116.766 126.108 117 124.782
            117C124.314 117 123.456 117 122.208 117C121.038 116.922 119.751
            116.571 118.347 115.947C117.021 115.245 115.89 114.036 114.954
            112.32C114.018 110.526 113.55 107.913 113.55 104.481V43.29C113.55
            42.666 113.784 42.12 114.252 41.652C114.72 41.184 115.266 40.95
            115.89 40.95ZM188.711 58.617C189.413 58.617 189.959 58.851 190.349
            59.319C190.817 59.787 191.051 60.333 191.051 60.957V95.004C191.051
            102.57 188.906 108.264 184.616 112.086C180.404 115.83 174.788
            117.702 167.768 117.702C160.826 117.702 155.21 115.83 150.92
            112.086C146.708 108.264 144.602 102.57 144.602 95.004V60.957C144.602
            60.333 144.836 59.787 145.304 59.319C145.772 58.851 146.318 58.617
            146.942 58.617C147.644 58.617 148.19 58.851 148.58 59.319C149.048
            59.787 149.282 60.333 149.282 60.957V95.004C149.282 100.932 150.92
            105.456 154.196 108.576C157.55 111.696 162.074 113.256 167.768
            113.256C173.54 113.256 178.064 111.696 181.34 108.576C184.694
            105.456 186.371 100.932 186.371 95.004V60.957C186.371 60.333 186.566
            59.787 186.956 59.319C187.424 58.851 188.009 58.617 188.711
            58.617ZM256.627 30.42C257.329 30.42 257.875 30.654 258.265
            31.122C258.733 31.59 258.967 32.136 258.967 32.76V114.66C258.967
            115.284 258.733 115.83 258.265 116.298C257.797 116.766 257.251 117
            256.627 117C255.925 117 255.34 116.766 254.872 116.298C254.482
            115.83 254.287 115.284 254.287 114.66V98.046L256.159 95.472C256.159
            98.124 255.535 100.815 254.287 103.545C253.117 106.197 251.401
            108.654 249.139 110.916C246.955 113.1 244.381 114.855 241.417
            116.181C238.453 117.507 235.255 118.17 231.823 118.17C226.675 118.17
            222.034 116.844 217.9 114.192C213.844 111.462 210.607 107.796
            208.189 103.194C205.849 98.514 204.679 93.249 204.679 87.399C204.679
            81.549 205.849 76.323 208.189 71.721C210.607 67.041 213.844 63.375
            217.9 60.723C222.034 58.071 226.675 56.745 231.823 56.745C235.021
            56.745 238.063 57.369 240.949 58.617C243.913 59.787 246.526 61.464
            248.788 63.648C251.05 65.832 252.844 68.406 254.17 71.37C255.496
            74.256 256.159 77.415 256.159 80.847L254.287 77.922V32.76C254.287
            32.136 254.482 31.59 254.872 31.122C255.34 30.654 255.925 30.42
            256.627 30.42ZM232.057 113.724C236.503 113.724 240.403 112.593
            243.757 110.331C247.189 108.069 249.88 104.949 251.83 100.971C253.78
            96.993 254.755 92.469 254.755 87.399C254.755 82.329 253.78 77.844
            251.83 73.944C249.88 69.966 247.189 66.846 243.757 64.584C240.325
            62.322 236.425 61.191 232.057 61.191C227.767 61.191 223.906 62.322
            220.474 64.584C217.042 66.846 214.312 69.966 212.284 73.944C210.334
            77.844 209.359 82.329 209.359 87.399C209.359 92.391 210.334 96.876
            212.284 100.854C214.312 104.832 217.042 107.991 220.474
            110.331C223.906 112.593 227.767 113.724 232.057 113.724ZM281.282
            114.66C281.282 115.284 281.048 115.83 280.58 116.298C280.112 116.766
            279.566 117 278.942 117C278.24 117 277.655 116.766 277.187
            116.298C276.797 115.83 276.602 115.284 276.602 114.66V59.085C276.602
            58.461 276.836 57.915 277.304 57.447C277.772 56.979 278.318 56.745
            278.942 56.745C279.644 56.745 280.19 56.979 280.58 57.447C281.048
            57.915 281.282 58.461 281.282 59.085V114.66ZM278.942 48.789C277.694
            48.789 276.68 48.438 275.9 47.736C275.198 47.034 274.847 46.137
            274.847 45.045V44.109C274.847 43.017 275.237 42.12 276.017
            41.418C276.797 40.716 277.811 40.365 279.059 40.365C280.151 40.365
            281.048 40.716 281.75 41.418C282.53 42.12 282.92 43.017 282.92
            44.109V45.045C282.92 46.137 282.53 47.034 281.75 47.736C281.048
            48.438 280.112 48.789 278.942 48.789ZM352.541 87.516C352.541 93.288
            351.293 98.514 348.797 103.194C346.301 107.796 342.869 111.462
            338.501 114.192C334.211 116.844 329.297 118.17 323.759
            118.17C318.299 118.17 313.385 116.844 309.017 114.192C304.649
            111.462 301.178 107.796 298.604 103.194C296.108 98.514 294.86 93.288
            294.86 87.516C294.86 81.666 296.108 76.44 298.604 71.838C301.178
            67.158 304.649 63.492 309.017 60.84C313.385 58.11 318.299 56.745
            323.759 56.745C329.297 56.745 334.211 58.11 338.501 60.84C342.869
            63.492 346.301 67.158 348.797 71.838C351.293 76.44 352.541 81.666
            352.541 87.516ZM347.861 87.516C347.861 82.446 346.808 77.961 344.702
            74.061C342.674 70.083 339.827 66.963 336.161 64.701C332.573 62.361
            328.439 61.191 323.759 61.191C319.157 61.191 315.023 62.361 311.357
            64.701C307.691 66.963 304.805 70.083 302.699 74.061C300.593 77.961
            299.54 82.446 299.54 87.516C299.54 92.508 300.593 96.993 302.699
            100.971C304.805 104.871 307.691 107.991 311.357 110.331C315.023
            112.593 319.157 113.724 323.759 113.724C328.439 113.724 332.573
            112.593 336.161 110.331C339.827 107.991 342.674 104.871 344.702
            100.971C346.808 96.993 347.861 92.508 347.861 87.516ZM410.207
            58.617C410.909 58.617 411.455 58.851 411.845 59.319C412.313 59.787
            412.547 60.333 412.547 60.957V95.004C412.547 102.57 410.402 108.264
            406.112 112.086C401.9 115.83 396.284 117.702 389.264 117.702C382.322
            117.702 376.706 115.83 372.416 112.086C368.204 108.264 366.098
            102.57 366.098 95.004V60.957C366.098 60.333 366.332 59.787 366.8
            59.319C367.268 58.851 367.814 58.617 368.438 58.617C369.14 58.617
            369.686 58.851 370.076 59.319C370.544 59.787 370.778 60.333 370.778
            60.957V95.004C370.778 100.932 372.416 105.456 375.692
            108.576C379.046 111.696 383.57 113.256 389.264 113.256C395.036
            113.256 399.56 111.696 402.836 108.576C406.19 105.456 407.867
            100.932 407.867 95.004V60.957C407.867 60.333 408.062 59.787 408.452
            59.319C408.92 58.851 409.505 58.617 410.207 58.617ZM426.994
            107.64C426.448 106.704 426.175 105.963 426.175 105.417C426.253
            104.793 426.565 104.286 427.111 103.896C427.501 103.506 428.008
            103.35 428.632 103.428C429.334 103.428 429.919 103.701 430.387
            104.247C432.415 107.133 434.989 109.473 438.109 111.267C441.307
            113.061 445.051 113.958 449.341 113.958C451.915 113.958 454.372
            113.529 456.712 112.671C459.13 111.813 461.08 110.526 462.562
            108.81C464.122 107.094 464.902 104.988 464.902 102.492C464.902 99.84
            464.161 97.734 462.679 96.174C461.197 94.536 459.286 93.249 456.946
            92.313C454.606 91.377 452.149 90.597 449.575 89.973C446.845 89.271
            444.232 88.491 441.736 87.633C439.318 86.697 437.173 85.566 435.301
            84.24C433.429 82.836 431.947 81.198 430.855 79.326C429.763 77.454
            429.217 75.231 429.217 72.657C429.217 69.615 430.036 66.885 431.674
            64.467C433.312 62.049 435.613 60.177 438.577 58.851C441.541 57.447
            444.973 56.745 448.873 56.745C450.745 56.745 452.734 56.979 454.84
            57.447C456.946 57.915 459.052 58.734 461.158 59.904C463.264 60.996
            465.214 62.556 467.008 64.584C467.632 65.052 467.905 65.637 467.827
            66.339C467.827 66.963 467.554 67.548 467.008 68.094C466.54 68.406
            466.033 68.562 465.487 68.562C464.941 68.562 464.473 68.328 464.083
            67.86C462.055 65.598 459.676 63.921 456.946 62.829C454.294 61.737
            451.447 61.191 448.405 61.191C445.831 61.191 443.413 61.62 441.151
            62.478C438.967 63.336 437.173 64.623 435.769 66.339C434.365 67.977
            433.663 70.083 433.663 72.657C433.819 75.075 434.638 77.064 436.12
            78.624C437.68 80.184 439.708 81.471 442.204 82.485C444.778 83.499
            447.664 84.435 450.862 85.293C453.436 85.917 455.815 86.658 457.999
            87.516C460.261 88.296 462.211 89.31 463.849 90.558C465.565 91.806
            466.891 93.366 467.827 95.238C468.841 97.11 469.348 99.45 469.348
            102.258C469.348 105.534 468.451 108.381 466.657 110.799C464.941
            113.139 462.523 114.972 459.403 116.298C456.361 117.546 452.851
            118.17 448.873 118.17C444.817 118.17 440.917 117.351 437.173
            115.713C433.429 114.075 430.036 111.384 426.994 107.64Z"
      fill="none"
      stroke="#e3e3e3"
      stroke-width="1px"
      stroke-linejoin="round"
    />

    <path
      d="M70.1656 118.17C64.0816 118.248 58.8556 117.234 54.4876
            115.128C50.1196 113.022 45.9076 109.941 41.8516 105.885C41.6956
            105.729 41.5396 105.534 41.3836 105.3C41.2276 104.988 41.1496
            104.598 41.1496 104.13C41.1496 103.506 41.4226 102.921 41.9686
            102.375C42.5146 101.751 43.0996 101.439 43.7236 101.439C44.4256
            101.439 45.0886 101.79 45.7126 102.492C48.8326 106.08 52.4596 108.81
            56.5936 110.682C60.8056 112.554 65.1736 113.49 69.6976
            113.49C73.6756 113.49 77.2246 112.866 80.3446 111.618C83.4646
            110.292 85.9216 108.459 87.7156 106.119C89.5096 103.701 90.4066
            100.893 90.4066 97.695C90.3286 93.717 89.2366 90.519 87.1306
            88.101C85.0246 85.683 82.2946 83.694 78.9406 82.134C75.6646 80.574
            72.1936 79.131 68.5276 77.805C65.6416 76.713 62.7946 75.582 59.9866
            74.412C57.1786 73.242 54.6436 71.838 52.3816 70.2C50.1196 68.484
            48.3256 66.378 46.9996 63.882C45.6736 61.308 45.0106 58.149 45.0106
            54.405C45.0106 50.583 46.0246 47.151 48.0526 44.109C50.1586 40.989
            53.1226 38.532 56.9446 36.738C60.8446 34.866 65.4466 33.93 70.7506
            33.93C75.1966 33.93 79.5256 34.749 83.7376 36.387C87.9496 37.947
            91.2646 40.326 93.6826 43.524C94.4626 44.616 94.8526 45.435 94.8526
            45.981C94.8526 46.527 94.5406 47.073 93.9166 47.619C93.3706 48.165
            92.7466 48.438 92.0446 48.438C91.4206 48.438 90.9136 48.204 90.5236
            47.736C89.1196 45.864 87.4036 44.265 85.3756 42.939C83.4256 41.535
            81.2026 40.482 78.7066 39.78C76.2106 39 73.5586 38.61 70.7506
            38.61C66.9286 38.61 63.4576 39.234 60.3376 40.482C57.2176 41.73
            54.7216 43.524 52.8496 45.864C50.9776 48.126 50.0416 50.934 50.0416
            54.288C50.0416 57.876 51.0556 60.84 53.0836 63.18C55.1896 65.442
            57.8416 67.353 61.0396 68.913C64.2376 70.395 67.5136 71.76 70.8676
            73.008C73.8316 74.022 76.7566 75.153 79.6426 76.401C82.6066 77.571
            85.2586 79.053 87.5986 80.847C90.0166 82.641 91.9276 84.903 93.3316
            87.633C94.7356 90.363 95.4376 93.795 95.4376 97.929C95.4376 101.517
            94.3846 104.832 92.2786 107.874C90.2506 110.916 87.3646 113.373
            83.6206 115.245C79.8766 117.117 75.3916 118.092 70.1656
            118.17ZM103.956 58.149H131.1C131.724 58.149 132.231 58.383 132.621
            58.851C133.089 59.319 133.323 59.826 133.323 60.372C133.323 60.996
            133.089 61.542 132.621 62.01C132.231 62.4 131.724 62.595 131.1
            62.595H103.956C103.41 62.595 102.903 62.361 102.435 61.893C101.967
            61.425 101.733 60.918 101.733 60.372C101.733 59.748 101.967 59.241
            102.435 58.851C102.903 58.383 103.41 58.149 103.956 58.149ZM115.89
            40.95C116.592 40.95 117.138 41.184 117.528 41.652C117.996 42.12
            118.23 42.666 118.23 43.29V104.247C118.23 106.821 118.542 108.732
            119.166 109.98C119.868 111.15 120.726 111.93 121.74 112.32C122.754
            112.632 123.729 112.788 124.665 112.788C125.211 112.788 125.679
            112.71 126.069 112.554C126.459 112.398 126.927 112.32 127.473
            112.32C128.019 112.32 128.487 112.554 128.877 113.022C129.267
            113.412 129.462 113.88 129.462 114.426C129.462 115.128 128.994
            115.752 128.058 116.298C127.2 116.766 126.108 117 124.782
            117C124.314 117 123.456 117 122.208 117C121.038 116.922 119.751
            116.571 118.347 115.947C117.021 115.245 115.89 114.036 114.954
            112.32C114.018 110.526 113.55 107.913 113.55 104.481V43.29C113.55
            42.666 113.784 42.12 114.252 41.652C114.72 41.184 115.266 40.95
            115.89 40.95ZM188.711 58.617C189.413 58.617 189.959 58.851 190.349
            59.319C190.817 59.787 191.051 60.333 191.051 60.957V95.004C191.051
            102.57 188.906 108.264 184.616 112.086C180.404 115.83 174.788
            117.702 167.768 117.702C160.826 117.702 155.21 115.83 150.92
            112.086C146.708 108.264 144.602 102.57 144.602 95.004V60.957C144.602
            60.333 144.836 59.787 145.304 59.319C145.772 58.851 146.318 58.617
            146.942 58.617C147.644 58.617 148.19 58.851 148.58 59.319C149.048
            59.787 149.282 60.333 149.282 60.957V95.004C149.282 100.932 150.92
            105.456 154.196 108.576C157.55 111.696 162.074 113.256 167.768
            113.256C173.54 113.256 178.064 111.696 181.34 108.576C184.694
            105.456 186.371 100.932 186.371 95.004V60.957C186.371 60.333 186.566
            59.787 186.956 59.319C187.424 58.851 188.009 58.617 188.711
            58.617ZM256.627 30.42C257.329 30.42 257.875 30.654 258.265
            31.122C258.733 31.59 258.967 32.136 258.967 32.76V114.66C258.967
            115.284 258.733 115.83 258.265 116.298C257.797 116.766 257.251 117
            256.627 117C255.925 117 255.34 116.766 254.872 116.298C254.482
            115.83 254.287 115.284 254.287 114.66V98.046L256.159 95.472C256.159
            98.124 255.535 100.815 254.287 103.545C253.117 106.197 251.401
            108.654 249.139 110.916C246.955 113.1 244.381 114.855 241.417
            116.181C238.453 117.507 235.255 118.17 231.823 118.17C226.675 118.17
            222.034 116.844 217.9 114.192C213.844 111.462 210.607 107.796
            208.189 103.194C205.849 98.514 204.679 93.249 204.679 87.399C204.679
            81.549 205.849 76.323 208.189 71.721C210.607 67.041 213.844 63.375
            217.9 60.723C222.034 58.071 226.675 56.745 231.823 56.745C235.021
            56.745 238.063 57.369 240.949 58.617C243.913 59.787 246.526 61.464
            248.788 63.648C251.05 65.832 252.844 68.406 254.17 71.37C255.496
            74.256 256.159 77.415 256.159 80.847L254.287 77.922V32.76C254.287
            32.136 254.482 31.59 254.872 31.122C255.34 30.654 255.925 30.42
            256.627 30.42ZM232.057 113.724C236.503 113.724 240.403 112.593
            243.757 110.331C247.189 108.069 249.88 104.949 251.83 100.971C253.78
            96.993 254.755 92.469 254.755 87.399C254.755 82.329 253.78 77.844
            251.83 73.944C249.88 69.966 247.189 66.846 243.757 64.584C240.325
            62.322 236.425 61.191 232.057 61.191C227.767 61.191 223.906 62.322
            220.474 64.584C217.042 66.846 214.312 69.966 212.284 73.944C210.334
            77.844 209.359 82.329 209.359 87.399C209.359 92.391 210.334 96.876
            212.284 100.854C214.312 104.832 217.042 107.991 220.474
            110.331C223.906 112.593 227.767 113.724 232.057 113.724ZM281.282
            114.66C281.282 115.284 281.048 115.83 280.58 116.298C280.112 116.766
            279.566 117 278.942 117C278.24 117 277.655 116.766 277.187
            116.298C276.797 115.83 276.602 115.284 276.602 114.66V59.085C276.602
            58.461 276.836 57.915 277.304 57.447C277.772 56.979 278.318 56.745
            278.942 56.745C279.644 56.745 280.19 56.979 280.58 57.447C281.048
            57.915 281.282 58.461 281.282 59.085V114.66ZM278.942 48.789C277.694
            48.789 276.68 48.438 275.9 47.736C275.198 47.034 274.847 46.137
            274.847 45.045V44.109C274.847 43.017 275.237 42.12 276.017
            41.418C276.797 40.716 277.811 40.365 279.059 40.365C280.151 40.365
            281.048 40.716 281.75 41.418C282.53 42.12 282.92 43.017 282.92
            44.109V45.045C282.92 46.137 282.53 47.034 281.75 47.736C281.048
            48.438 280.112 48.789 278.942 48.789ZM352.541 87.516C352.541 93.288
            351.293 98.514 348.797 103.194C346.301 107.796 342.869 111.462
            338.501 114.192C334.211 116.844 329.297 118.17 323.759
            118.17C318.299 118.17 313.385 116.844 309.017 114.192C304.649
            111.462 301.178 107.796 298.604 103.194C296.108 98.514 294.86 93.288
            294.86 87.516C294.86 81.666 296.108 76.44 298.604 71.838C301.178
            67.158 304.649 63.492 309.017 60.84C313.385 58.11 318.299 56.745
            323.759 56.745C329.297 56.745 334.211 58.11 338.501 60.84C342.869
            63.492 346.301 67.158 348.797 71.838C351.293 76.44 352.541 81.666
            352.541 87.516ZM347.861 87.516C347.861 82.446 346.808 77.961 344.702
            74.061C342.674 70.083 339.827 66.963 336.161 64.701C332.573 62.361
            328.439 61.191 323.759 61.191C319.157 61.191 315.023 62.361 311.357
            64.701C307.691 66.963 304.805 70.083 302.699 74.061C300.593 77.961
            299.54 82.446 299.54 87.516C299.54 92.508 300.593 96.993 302.699
            100.971C304.805 104.871 307.691 107.991 311.357 110.331C315.023
            112.593 319.157 113.724 323.759 113.724C328.439 113.724 332.573
            112.593 336.161 110.331C339.827 107.991 342.674 104.871 344.702
            100.971C346.808 96.993 347.861 92.508 347.861 87.516ZM410.207
            58.617C410.909 58.617 411.455 58.851 411.845 59.319C412.313 59.787
            412.547 60.333 412.547 60.957V95.004C412.547 102.57 410.402 108.264
            406.112 112.086C401.9 115.83 396.284 117.702 389.264 117.702C382.322
            117.702 376.706 115.83 372.416 112.086C368.204 108.264 366.098
            102.57 366.098 95.004V60.957C366.098 60.333 366.332 59.787 366.8
            59.319C367.268 58.851 367.814 58.617 368.438 58.617C369.14 58.617
            369.686 58.851 370.076 59.319C370.544 59.787 370.778 60.333 370.778
            60.957V95.004C370.778 100.932 372.416 105.456 375.692
            108.576C379.046 111.696 383.57 113.256 389.264 113.256C395.036
            113.256 399.56 111.696 402.836 108.576C406.19 105.456 407.867
            100.932 407.867 95.004V60.957C407.867 60.333 408.062 59.787 408.452
            59.319C408.92 58.851 409.505 58.617 410.207 58.617ZM426.994
            107.64C426.448 106.704 426.175 105.963 426.175 105.417C426.253
            104.793 426.565 104.286 427.111 103.896C427.501 103.506 428.008
            103.35 428.632 103.428C429.334 103.428 429.919 103.701 430.387
            104.247C432.415 107.133 434.989 109.473 438.109 111.267C441.307
            113.061 445.051 113.958 449.341 113.958C451.915 113.958 454.372
            113.529 456.712 112.671C459.13 111.813 461.08 110.526 462.562
            108.81C464.122 107.094 464.902 104.988 464.902 102.492C464.902 99.84
            464.161 97.734 462.679 96.174C461.197 94.536 459.286 93.249 456.946
            92.313C454.606 91.377 452.149 90.597 449.575 89.973C446.845 89.271
            444.232 88.491 441.736 87.633C439.318 86.697 437.173 85.566 435.301
            84.24C433.429 82.836 431.947 81.198 430.855 79.326C429.763 77.454
            429.217 75.231 429.217 72.657C429.217 69.615 430.036 66.885 431.674
            64.467C433.312 62.049 435.613 60.177 438.577 58.851C441.541 57.447
            444.973 56.745 448.873 56.745C450.745 56.745 452.734 56.979 454.84
            57.447C456.946 57.915 459.052 58.734 461.158 59.904C463.264 60.996
            465.214 62.556 467.008 64.584C467.632 65.052 467.905 65.637 467.827
            66.339C467.827 66.963 467.554 67.548 467.008 68.094C466.54 68.406
            466.033 68.562 465.487 68.562C464.941 68.562 464.473 68.328 464.083
            67.86C462.055 65.598 459.676 63.921 456.946 62.829C454.294 61.737
            451.447 61.191 448.405 61.191C445.831 61.191 443.413 61.62 441.151
            62.478C438.967 63.336 437.173 64.623 435.769 66.339C434.365 67.977
            433.663 70.083 433.663 72.657C433.819 75.075 434.638 77.064 436.12
            78.624C437.68 80.184 439.708 81.471 442.204 82.485C444.778 83.499
            447.664 84.435 450.862 85.293C453.436 85.917 455.815 86.658 457.999
            87.516C460.261 88.296 462.211 89.31 463.849 90.558C465.565 91.806
            466.891 93.366 467.827 95.238C468.841 97.11 469.348 99.45 469.348
            102.258C469.348 105.534 468.451 108.381 466.657 110.799C464.941
            113.139 462.523 114.972 459.403 116.298C456.361 117.546 452.851
            118.17 448.873 118.17C444.817 118.17 440.917 117.351 437.173
            115.713C433.429 114.075 430.036 111.384 426.994 107.64Z"
      fill="#e3e3e3"
    />
    <ellipse
      cx="12.5"
      cy="45.5"
      rx="12.5"
      ry="12.5"
      transform="rotate(90 12.5 45.5)"
      stroke="#E06C75"
    />
    <circle
      cx="12.5"
      cy="75.5"
      r="12.5"
      transform="rotate(90 12.5 75.5)"
      stroke="#E5C07B"
    />
    <ellipse
      cx="12.5"
      cy="106.5"
      rx="12.5"
      ry="12.5"
      transform="rotate(90 12.5 106.5)"
      stroke="#98C379"
    />
    <ellipse
      cx="12.5"
      cy="45.5"
      rx="12.5"
      ry="12.5"
      transform="rotate(90 12.5 45.5)"
      fill="#E06C75"
    />
    <circle
      cx="12.5"
      cy="75.5"
      r="12.5"
      transform="rotate(90 12.5 75.5)"
      fill="#E5C07B"
    />
    <ellipse
      cx="12.5"
      cy="106.5"
      rx="12.5"
      ry="12.5"
      transform="rotate(90 12.5 106.5)"
      fill="#98C379"
    />
  </svg>
{:else}
  <svg viewBox="0 0 475 146" fill="none" xmlns="http://www.w3.org/2000/svg">
    {#if condition}
      <path
        in:draw={{ duration: 70000, delay: 500, easing: quintOut }}
        d="M70.1656 118.17C64.0816 118.248 58.8556 117.234 54.4876
            115.128C50.1196 113.022 45.9076 109.941 41.8516 105.885C41.6956
            105.729 41.5396 105.534 41.3836 105.3C41.2276 104.988 41.1496
            104.598 41.1496 104.13C41.1496 103.506 41.4226 102.921 41.9686
            102.375C42.5146 101.751 43.0996 101.439 43.7236 101.439C44.4256
            101.439 45.0886 101.79 45.7126 102.492C48.8326 106.08 52.4596 108.81
            56.5936 110.682C60.8056 112.554 65.1736 113.49 69.6976
            113.49C73.6756 113.49 77.2246 112.866 80.3446 111.618C83.4646
            110.292 85.9216 108.459 87.7156 106.119C89.5096 103.701 90.4066
            100.893 90.4066 97.695C90.3286 93.717 89.2366 90.519 87.1306
            88.101C85.0246 85.683 82.2946 83.694 78.9406 82.134C75.6646 80.574
            72.1936 79.131 68.5276 77.805C65.6416 76.713 62.7946 75.582 59.9866
            74.412C57.1786 73.242 54.6436 71.838 52.3816 70.2C50.1196 68.484
            48.3256 66.378 46.9996 63.882C45.6736 61.308 45.0106 58.149 45.0106
            54.405C45.0106 50.583 46.0246 47.151 48.0526 44.109C50.1586 40.989
            53.1226 38.532 56.9446 36.738C60.8446 34.866 65.4466 33.93 70.7506
            33.93C75.1966 33.93 79.5256 34.749 83.7376 36.387C87.9496 37.947
            91.2646 40.326 93.6826 43.524C94.4626 44.616 94.8526 45.435 94.8526
            45.981C94.8526 46.527 94.5406 47.073 93.9166 47.619C93.3706 48.165
            92.7466 48.438 92.0446 48.438C91.4206 48.438 90.9136 48.204 90.5236
            47.736C89.1196 45.864 87.4036 44.265 85.3756 42.939C83.4256 41.535
            81.2026 40.482 78.7066 39.78C76.2106 39 73.5586 38.61 70.7506
            38.61C66.9286 38.61 63.4576 39.234 60.3376 40.482C57.2176 41.73
            54.7216 43.524 52.8496 45.864C50.9776 48.126 50.0416 50.934 50.0416
            54.288C50.0416 57.876 51.0556 60.84 53.0836 63.18C55.1896 65.442
            57.8416 67.353 61.0396 68.913C64.2376 70.395 67.5136 71.76 70.8676
            73.008C73.8316 74.022 76.7566 75.153 79.6426 76.401C82.6066 77.571
            85.2586 79.053 87.5986 80.847C90.0166 82.641 91.9276 84.903 93.3316
            87.633C94.7356 90.363 95.4376 93.795 95.4376 97.929C95.4376 101.517
            94.3846 104.832 92.2786 107.874C90.2506 110.916 87.3646 113.373
            83.6206 115.245C79.8766 117.117 75.3916 118.092 70.1656
            118.17ZM103.956 58.149H131.1C131.724 58.149 132.231 58.383 132.621
            58.851C133.089 59.319 133.323 59.826 133.323 60.372C133.323 60.996
            133.089 61.542 132.621 62.01C132.231 62.4 131.724 62.595 131.1
            62.595H103.956C103.41 62.595 102.903 62.361 102.435 61.893C101.967
            61.425 101.733 60.918 101.733 60.372C101.733 59.748 101.967 59.241
            102.435 58.851C102.903 58.383 103.41 58.149 103.956 58.149ZM115.89
            40.95C116.592 40.95 117.138 41.184 117.528 41.652C117.996 42.12
            118.23 42.666 118.23 43.29V104.247C118.23 106.821 118.542 108.732
            119.166 109.98C119.868 111.15 120.726 111.93 121.74 112.32C122.754
            112.632 123.729 112.788 124.665 112.788C125.211 112.788 125.679
            112.71 126.069 112.554C126.459 112.398 126.927 112.32 127.473
            112.32C128.019 112.32 128.487 112.554 128.877 113.022C129.267
            113.412 129.462 113.88 129.462 114.426C129.462 115.128 128.994
            115.752 128.058 116.298C127.2 116.766 126.108 117 124.782
            117C124.314 117 123.456 117 122.208 117C121.038 116.922 119.751
            116.571 118.347 115.947C117.021 115.245 115.89 114.036 114.954
            112.32C114.018 110.526 113.55 107.913 113.55 104.481V43.29C113.55
            42.666 113.784 42.12 114.252 41.652C114.72 41.184 115.266 40.95
            115.89 40.95ZM188.711 58.617C189.413 58.617 189.959 58.851 190.349
            59.319C190.817 59.787 191.051 60.333 191.051 60.957V95.004C191.051
            102.57 188.906 108.264 184.616 112.086C180.404 115.83 174.788
            117.702 167.768 117.702C160.826 117.702 155.21 115.83 150.92
            112.086C146.708 108.264 144.602 102.57 144.602 95.004V60.957C144.602
            60.333 144.836 59.787 145.304 59.319C145.772 58.851 146.318 58.617
            146.942 58.617C147.644 58.617 148.19 58.851 148.58 59.319C149.048
            59.787 149.282 60.333 149.282 60.957V95.004C149.282 100.932 150.92
            105.456 154.196 108.576C157.55 111.696 162.074 113.256 167.768
            113.256C173.54 113.256 178.064 111.696 181.34 108.576C184.694
            105.456 186.371 100.932 186.371 95.004V60.957C186.371 60.333 186.566
            59.787 186.956 59.319C187.424 58.851 188.009 58.617 188.711
            58.617ZM256.627 30.42C257.329 30.42 257.875 30.654 258.265
            31.122C258.733 31.59 258.967 32.136 258.967 32.76V114.66C258.967
            115.284 258.733 115.83 258.265 116.298C257.797 116.766 257.251 117
            256.627 117C255.925 117 255.34 116.766 254.872 116.298C254.482
            115.83 254.287 115.284 254.287 114.66V98.046L256.159 95.472C256.159
            98.124 255.535 100.815 254.287 103.545C253.117 106.197 251.401
            108.654 249.139 110.916C246.955 113.1 244.381 114.855 241.417
            116.181C238.453 117.507 235.255 118.17 231.823 118.17C226.675 118.17
            222.034 116.844 217.9 114.192C213.844 111.462 210.607 107.796
            208.189 103.194C205.849 98.514 204.679 93.249 204.679 87.399C204.679
            81.549 205.849 76.323 208.189 71.721C210.607 67.041 213.844 63.375
            217.9 60.723C222.034 58.071 226.675 56.745 231.823 56.745C235.021
            56.745 238.063 57.369 240.949 58.617C243.913 59.787 246.526 61.464
            248.788 63.648C251.05 65.832 252.844 68.406 254.17 71.37C255.496
            74.256 256.159 77.415 256.159 80.847L254.287 77.922V32.76C254.287
            32.136 254.482 31.59 254.872 31.122C255.34 30.654 255.925 30.42
            256.627 30.42ZM232.057 113.724C236.503 113.724 240.403 112.593
            243.757 110.331C247.189 108.069 249.88 104.949 251.83 100.971C253.78
            96.993 254.755 92.469 254.755 87.399C254.755 82.329 253.78 77.844
            251.83 73.944C249.88 69.966 247.189 66.846 243.757 64.584C240.325
            62.322 236.425 61.191 232.057 61.191C227.767 61.191 223.906 62.322
            220.474 64.584C217.042 66.846 214.312 69.966 212.284 73.944C210.334
            77.844 209.359 82.329 209.359 87.399C209.359 92.391 210.334 96.876
            212.284 100.854C214.312 104.832 217.042 107.991 220.474
            110.331C223.906 112.593 227.767 113.724 232.057 113.724ZM281.282
            114.66C281.282 115.284 281.048 115.83 280.58 116.298C280.112 116.766
            279.566 117 278.942 117C278.24 117 277.655 116.766 277.187
            116.298C276.797 115.83 276.602 115.284 276.602 114.66V59.085C276.602
            58.461 276.836 57.915 277.304 57.447C277.772 56.979 278.318 56.745
            278.942 56.745C279.644 56.745 280.19 56.979 280.58 57.447C281.048
            57.915 281.282 58.461 281.282 59.085V114.66ZM278.942 48.789C277.694
            48.789 276.68 48.438 275.9 47.736C275.198 47.034 274.847 46.137
            274.847 45.045V44.109C274.847 43.017 275.237 42.12 276.017
            41.418C276.797 40.716 277.811 40.365 279.059 40.365C280.151 40.365
            281.048 40.716 281.75 41.418C282.53 42.12 282.92 43.017 282.92
            44.109V45.045C282.92 46.137 282.53 47.034 281.75 47.736C281.048
            48.438 280.112 48.789 278.942 48.789ZM352.541 87.516C352.541 93.288
            351.293 98.514 348.797 103.194C346.301 107.796 342.869 111.462
            338.501 114.192C334.211 116.844 329.297 118.17 323.759
            118.17C318.299 118.17 313.385 116.844 309.017 114.192C304.649
            111.462 301.178 107.796 298.604 103.194C296.108 98.514 294.86 93.288
            294.86 87.516C294.86 81.666 296.108 76.44 298.604 71.838C301.178
            67.158 304.649 63.492 309.017 60.84C313.385 58.11 318.299 56.745
            323.759 56.745C329.297 56.745 334.211 58.11 338.501 60.84C342.869
            63.492 346.301 67.158 348.797 71.838C351.293 76.44 352.541 81.666
            352.541 87.516ZM347.861 87.516C347.861 82.446 346.808 77.961 344.702
            74.061C342.674 70.083 339.827 66.963 336.161 64.701C332.573 62.361
            328.439 61.191 323.759 61.191C319.157 61.191 315.023 62.361 311.357
            64.701C307.691 66.963 304.805 70.083 302.699 74.061C300.593 77.961
            299.54 82.446 299.54 87.516C299.54 92.508 300.593 96.993 302.699
            100.971C304.805 104.871 307.691 107.991 311.357 110.331C315.023
            112.593 319.157 113.724 323.759 113.724C328.439 113.724 332.573
            112.593 336.161 110.331C339.827 107.991 342.674 104.871 344.702
            100.971C346.808 96.993 347.861 92.508 347.861 87.516ZM410.207
            58.617C410.909 58.617 411.455 58.851 411.845 59.319C412.313 59.787
            412.547 60.333 412.547 60.957V95.004C412.547 102.57 410.402 108.264
            406.112 112.086C401.9 115.83 396.284 117.702 389.264 117.702C382.322
            117.702 376.706 115.83 372.416 112.086C368.204 108.264 366.098
            102.57 366.098 95.004V60.957C366.098 60.333 366.332 59.787 366.8
            59.319C367.268 58.851 367.814 58.617 368.438 58.617C369.14 58.617
            369.686 58.851 370.076 59.319C370.544 59.787 370.778 60.333 370.778
            60.957V95.004C370.778 100.932 372.416 105.456 375.692
            108.576C379.046 111.696 383.57 113.256 389.264 113.256C395.036
            113.256 399.56 111.696 402.836 108.576C406.19 105.456 407.867
            100.932 407.867 95.004V60.957C407.867 60.333 408.062 59.787 408.452
            59.319C408.92 58.851 409.505 58.617 410.207 58.617ZM426.994
            107.64C426.448 106.704 426.175 105.963 426.175 105.417C426.253
            104.793 426.565 104.286 427.111 103.896C427.501 103.506 428.008
            103.35 428.632 103.428C429.334 103.428 429.919 103.701 430.387
            104.247C432.415 107.133 434.989 109.473 438.109 111.267C441.307
            113.061 445.051 113.958 449.341 113.958C451.915 113.958 454.372
            113.529 456.712 112.671C459.13 111.813 461.08 110.526 462.562
            108.81C464.122 107.094 464.902 104.988 464.902 102.492C464.902 99.84
            464.161 97.734 462.679 96.174C461.197 94.536 459.286 93.249 456.946
            92.313C454.606 91.377 452.149 90.597 449.575 89.973C446.845 89.271
            444.232 88.491 441.736 87.633C439.318 86.697 437.173 85.566 435.301
            84.24C433.429 82.836 431.947 81.198 430.855 79.326C429.763 77.454
            429.217 75.231 429.217 72.657C429.217 69.615 430.036 66.885 431.674
            64.467C433.312 62.049 435.613 60.177 438.577 58.851C441.541 57.447
            444.973 56.745 448.873 56.745C450.745 56.745 452.734 56.979 454.84
            57.447C456.946 57.915 459.052 58.734 461.158 59.904C463.264 60.996
            465.214 62.556 467.008 64.584C467.632 65.052 467.905 65.637 467.827
            66.339C467.827 66.963 467.554 67.548 467.008 68.094C466.54 68.406
            466.033 68.562 465.487 68.562C464.941 68.562 464.473 68.328 464.083
            67.86C462.055 65.598 459.676 63.921 456.946 62.829C454.294 61.737
            451.447 61.191 448.405 61.191C445.831 61.191 443.413 61.62 441.151
            62.478C438.967 63.336 437.173 64.623 435.769 66.339C434.365 67.977
            433.663 70.083 433.663 72.657C433.819 75.075 434.638 77.064 436.12
            78.624C437.68 80.184 439.708 81.471 442.204 82.485C444.778 83.499
            447.664 84.435 450.862 85.293C453.436 85.917 455.815 86.658 457.999
            87.516C460.261 88.296 462.211 89.31 463.849 90.558C465.565 91.806
            466.891 93.366 467.827 95.238C468.841 97.11 469.348 99.45 469.348
            102.258C469.348 105.534 468.451 108.381 466.657 110.799C464.941
            113.139 462.523 114.972 459.403 116.298C456.361 117.546 452.851
            118.17 448.873 118.17C444.817 118.17 440.917 117.351 437.173
            115.713C433.429 114.075 430.036 111.384 426.994 107.64Z"
        fill="none"
        stroke="#e3e3e3"
        stroke-width="1px"
        stroke-linejoin="round"
      />

      <path
        in:fade={{ duration: 500, delay: 2500, easing: quintOut }}
        d="M70.1656 118.17C64.0816 118.248 58.8556 117.234 54.4876
            115.128C50.1196 113.022 45.9076 109.941 41.8516 105.885C41.6956
            105.729 41.5396 105.534 41.3836 105.3C41.2276 104.988 41.1496
            104.598 41.1496 104.13C41.1496 103.506 41.4226 102.921 41.9686
            102.375C42.5146 101.751 43.0996 101.439 43.7236 101.439C44.4256
            101.439 45.0886 101.79 45.7126 102.492C48.8326 106.08 52.4596 108.81
            56.5936 110.682C60.8056 112.554 65.1736 113.49 69.6976
            113.49C73.6756 113.49 77.2246 112.866 80.3446 111.618C83.4646
            110.292 85.9216 108.459 87.7156 106.119C89.5096 103.701 90.4066
            100.893 90.4066 97.695C90.3286 93.717 89.2366 90.519 87.1306
            88.101C85.0246 85.683 82.2946 83.694 78.9406 82.134C75.6646 80.574
            72.1936 79.131 68.5276 77.805C65.6416 76.713 62.7946 75.582 59.9866
            74.412C57.1786 73.242 54.6436 71.838 52.3816 70.2C50.1196 68.484
            48.3256 66.378 46.9996 63.882C45.6736 61.308 45.0106 58.149 45.0106
            54.405C45.0106 50.583 46.0246 47.151 48.0526 44.109C50.1586 40.989
            53.1226 38.532 56.9446 36.738C60.8446 34.866 65.4466 33.93 70.7506
            33.93C75.1966 33.93 79.5256 34.749 83.7376 36.387C87.9496 37.947
            91.2646 40.326 93.6826 43.524C94.4626 44.616 94.8526 45.435 94.8526
            45.981C94.8526 46.527 94.5406 47.073 93.9166 47.619C93.3706 48.165
            92.7466 48.438 92.0446 48.438C91.4206 48.438 90.9136 48.204 90.5236
            47.736C89.1196 45.864 87.4036 44.265 85.3756 42.939C83.4256 41.535
            81.2026 40.482 78.7066 39.78C76.2106 39 73.5586 38.61 70.7506
            38.61C66.9286 38.61 63.4576 39.234 60.3376 40.482C57.2176 41.73
            54.7216 43.524 52.8496 45.864C50.9776 48.126 50.0416 50.934 50.0416
            54.288C50.0416 57.876 51.0556 60.84 53.0836 63.18C55.1896 65.442
            57.8416 67.353 61.0396 68.913C64.2376 70.395 67.5136 71.76 70.8676
            73.008C73.8316 74.022 76.7566 75.153 79.6426 76.401C82.6066 77.571
            85.2586 79.053 87.5986 80.847C90.0166 82.641 91.9276 84.903 93.3316
            87.633C94.7356 90.363 95.4376 93.795 95.4376 97.929C95.4376 101.517
            94.3846 104.832 92.2786 107.874C90.2506 110.916 87.3646 113.373
            83.6206 115.245C79.8766 117.117 75.3916 118.092 70.1656
            118.17ZM103.956 58.149H131.1C131.724 58.149 132.231 58.383 132.621
            58.851C133.089 59.319 133.323 59.826 133.323 60.372C133.323 60.996
            133.089 61.542 132.621 62.01C132.231 62.4 131.724 62.595 131.1
            62.595H103.956C103.41 62.595 102.903 62.361 102.435 61.893C101.967
            61.425 101.733 60.918 101.733 60.372C101.733 59.748 101.967 59.241
            102.435 58.851C102.903 58.383 103.41 58.149 103.956 58.149ZM115.89
            40.95C116.592 40.95 117.138 41.184 117.528 41.652C117.996 42.12
            118.23 42.666 118.23 43.29V104.247C118.23 106.821 118.542 108.732
            119.166 109.98C119.868 111.15 120.726 111.93 121.74 112.32C122.754
            112.632 123.729 112.788 124.665 112.788C125.211 112.788 125.679
            112.71 126.069 112.554C126.459 112.398 126.927 112.32 127.473
            112.32C128.019 112.32 128.487 112.554 128.877 113.022C129.267
            113.412 129.462 113.88 129.462 114.426C129.462 115.128 128.994
            115.752 128.058 116.298C127.2 116.766 126.108 117 124.782
            117C124.314 117 123.456 117 122.208 117C121.038 116.922 119.751
            116.571 118.347 115.947C117.021 115.245 115.89 114.036 114.954
            112.32C114.018 110.526 113.55 107.913 113.55 104.481V43.29C113.55
            42.666 113.784 42.12 114.252 41.652C114.72 41.184 115.266 40.95
            115.89 40.95ZM188.711 58.617C189.413 58.617 189.959 58.851 190.349
            59.319C190.817 59.787 191.051 60.333 191.051 60.957V95.004C191.051
            102.57 188.906 108.264 184.616 112.086C180.404 115.83 174.788
            117.702 167.768 117.702C160.826 117.702 155.21 115.83 150.92
            112.086C146.708 108.264 144.602 102.57 144.602 95.004V60.957C144.602
            60.333 144.836 59.787 145.304 59.319C145.772 58.851 146.318 58.617
            146.942 58.617C147.644 58.617 148.19 58.851 148.58 59.319C149.048
            59.787 149.282 60.333 149.282 60.957V95.004C149.282 100.932 150.92
            105.456 154.196 108.576C157.55 111.696 162.074 113.256 167.768
            113.256C173.54 113.256 178.064 111.696 181.34 108.576C184.694
            105.456 186.371 100.932 186.371 95.004V60.957C186.371 60.333 186.566
            59.787 186.956 59.319C187.424 58.851 188.009 58.617 188.711
            58.617ZM256.627 30.42C257.329 30.42 257.875 30.654 258.265
            31.122C258.733 31.59 258.967 32.136 258.967 32.76V114.66C258.967
            115.284 258.733 115.83 258.265 116.298C257.797 116.766 257.251 117
            256.627 117C255.925 117 255.34 116.766 254.872 116.298C254.482
            115.83 254.287 115.284 254.287 114.66V98.046L256.159 95.472C256.159
            98.124 255.535 100.815 254.287 103.545C253.117 106.197 251.401
            108.654 249.139 110.916C246.955 113.1 244.381 114.855 241.417
            116.181C238.453 117.507 235.255 118.17 231.823 118.17C226.675 118.17
            222.034 116.844 217.9 114.192C213.844 111.462 210.607 107.796
            208.189 103.194C205.849 98.514 204.679 93.249 204.679 87.399C204.679
            81.549 205.849 76.323 208.189 71.721C210.607 67.041 213.844 63.375
            217.9 60.723C222.034 58.071 226.675 56.745 231.823 56.745C235.021
            56.745 238.063 57.369 240.949 58.617C243.913 59.787 246.526 61.464
            248.788 63.648C251.05 65.832 252.844 68.406 254.17 71.37C255.496
            74.256 256.159 77.415 256.159 80.847L254.287 77.922V32.76C254.287
            32.136 254.482 31.59 254.872 31.122C255.34 30.654 255.925 30.42
            256.627 30.42ZM232.057 113.724C236.503 113.724 240.403 112.593
            243.757 110.331C247.189 108.069 249.88 104.949 251.83 100.971C253.78
            96.993 254.755 92.469 254.755 87.399C254.755 82.329 253.78 77.844
            251.83 73.944C249.88 69.966 247.189 66.846 243.757 64.584C240.325
            62.322 236.425 61.191 232.057 61.191C227.767 61.191 223.906 62.322
            220.474 64.584C217.042 66.846 214.312 69.966 212.284 73.944C210.334
            77.844 209.359 82.329 209.359 87.399C209.359 92.391 210.334 96.876
            212.284 100.854C214.312 104.832 217.042 107.991 220.474
            110.331C223.906 112.593 227.767 113.724 232.057 113.724ZM281.282
            114.66C281.282 115.284 281.048 115.83 280.58 116.298C280.112 116.766
            279.566 117 278.942 117C278.24 117 277.655 116.766 277.187
            116.298C276.797 115.83 276.602 115.284 276.602 114.66V59.085C276.602
            58.461 276.836 57.915 277.304 57.447C277.772 56.979 278.318 56.745
            278.942 56.745C279.644 56.745 280.19 56.979 280.58 57.447C281.048
            57.915 281.282 58.461 281.282 59.085V114.66ZM278.942 48.789C277.694
            48.789 276.68 48.438 275.9 47.736C275.198 47.034 274.847 46.137
            274.847 45.045V44.109C274.847 43.017 275.237 42.12 276.017
            41.418C276.797 40.716 277.811 40.365 279.059 40.365C280.151 40.365
            281.048 40.716 281.75 41.418C282.53 42.12 282.92 43.017 282.92
            44.109V45.045C282.92 46.137 282.53 47.034 281.75 47.736C281.048
            48.438 280.112 48.789 278.942 48.789ZM352.541 87.516C352.541 93.288
            351.293 98.514 348.797 103.194C346.301 107.796 342.869 111.462
            338.501 114.192C334.211 116.844 329.297 118.17 323.759
            118.17C318.299 118.17 313.385 116.844 309.017 114.192C304.649
            111.462 301.178 107.796 298.604 103.194C296.108 98.514 294.86 93.288
            294.86 87.516C294.86 81.666 296.108 76.44 298.604 71.838C301.178
            67.158 304.649 63.492 309.017 60.84C313.385 58.11 318.299 56.745
            323.759 56.745C329.297 56.745 334.211 58.11 338.501 60.84C342.869
            63.492 346.301 67.158 348.797 71.838C351.293 76.44 352.541 81.666
            352.541 87.516ZM347.861 87.516C347.861 82.446 346.808 77.961 344.702
            74.061C342.674 70.083 339.827 66.963 336.161 64.701C332.573 62.361
            328.439 61.191 323.759 61.191C319.157 61.191 315.023 62.361 311.357
            64.701C307.691 66.963 304.805 70.083 302.699 74.061C300.593 77.961
            299.54 82.446 299.54 87.516C299.54 92.508 300.593 96.993 302.699
            100.971C304.805 104.871 307.691 107.991 311.357 110.331C315.023
            112.593 319.157 113.724 323.759 113.724C328.439 113.724 332.573
            112.593 336.161 110.331C339.827 107.991 342.674 104.871 344.702
            100.971C346.808 96.993 347.861 92.508 347.861 87.516ZM410.207
            58.617C410.909 58.617 411.455 58.851 411.845 59.319C412.313 59.787
            412.547 60.333 412.547 60.957V95.004C412.547 102.57 410.402 108.264
            406.112 112.086C401.9 115.83 396.284 117.702 389.264 117.702C382.322
            117.702 376.706 115.83 372.416 112.086C368.204 108.264 366.098
            102.57 366.098 95.004V60.957C366.098 60.333 366.332 59.787 366.8
            59.319C367.268 58.851 367.814 58.617 368.438 58.617C369.14 58.617
            369.686 58.851 370.076 59.319C370.544 59.787 370.778 60.333 370.778
            60.957V95.004C370.778 100.932 372.416 105.456 375.692
            108.576C379.046 111.696 383.57 113.256 389.264 113.256C395.036
            113.256 399.56 111.696 402.836 108.576C406.19 105.456 407.867
            100.932 407.867 95.004V60.957C407.867 60.333 408.062 59.787 408.452
            59.319C408.92 58.851 409.505 58.617 410.207 58.617ZM426.994
            107.64C426.448 106.704 426.175 105.963 426.175 105.417C426.253
            104.793 426.565 104.286 427.111 103.896C427.501 103.506 428.008
            103.35 428.632 103.428C429.334 103.428 429.919 103.701 430.387
            104.247C432.415 107.133 434.989 109.473 438.109 111.267C441.307
            113.061 445.051 113.958 449.341 113.958C451.915 113.958 454.372
            113.529 456.712 112.671C459.13 111.813 461.08 110.526 462.562
            108.81C464.122 107.094 464.902 104.988 464.902 102.492C464.902 99.84
            464.161 97.734 462.679 96.174C461.197 94.536 459.286 93.249 456.946
            92.313C454.606 91.377 452.149 90.597 449.575 89.973C446.845 89.271
            444.232 88.491 441.736 87.633C439.318 86.697 437.173 85.566 435.301
            84.24C433.429 82.836 431.947 81.198 430.855 79.326C429.763 77.454
            429.217 75.231 429.217 72.657C429.217 69.615 430.036 66.885 431.674
            64.467C433.312 62.049 435.613 60.177 438.577 58.851C441.541 57.447
            444.973 56.745 448.873 56.745C450.745 56.745 452.734 56.979 454.84
            57.447C456.946 57.915 459.052 58.734 461.158 59.904C463.264 60.996
            465.214 62.556 467.008 64.584C467.632 65.052 467.905 65.637 467.827
            66.339C467.827 66.963 467.554 67.548 467.008 68.094C466.54 68.406
            466.033 68.562 465.487 68.562C464.941 68.562 464.473 68.328 464.083
            67.86C462.055 65.598 459.676 63.921 456.946 62.829C454.294 61.737
            451.447 61.191 448.405 61.191C445.831 61.191 443.413 61.62 441.151
            62.478C438.967 63.336 437.173 64.623 435.769 66.339C434.365 67.977
            433.663 70.083 433.663 72.657C433.819 75.075 434.638 77.064 436.12
            78.624C437.68 80.184 439.708 81.471 442.204 82.485C444.778 83.499
            447.664 84.435 450.862 85.293C453.436 85.917 455.815 86.658 457.999
            87.516C460.261 88.296 462.211 89.31 463.849 90.558C465.565 91.806
            466.891 93.366 467.827 95.238C468.841 97.11 469.348 99.45 469.348
            102.258C469.348 105.534 468.451 108.381 466.657 110.799C464.941
            113.139 462.523 114.972 459.403 116.298C456.361 117.546 452.851
            118.17 448.873 118.17C444.817 118.17 440.917 117.351 437.173
            115.713C433.429 114.075 430.036 111.384 426.994 107.64Z"
        fill="#e3e3e3"
      />
      <ellipse
        in:draw={{ duration: 4000, delay: 500, easing: quintOut }}
        cx="12.5"
        cy="45.5"
        rx="12.5"
        ry="12.5"
        transform="rotate(90 12.5 45.5)"
        stroke="#E06C75"
      />
      <circle
        in:draw={{ duration: 4000, delay: 500, easing: quintOut }}
        cx="12.5"
        cy="75.5"
        r="12.5"
        transform="rotate(90 12.5 75.5)"
        stroke="#E5C07B"
      />
      <ellipse
        in:draw={{ duration: 4000, delay: 500, easing: quintOut }}
        cx="12.5"
        cy="106.5"
        rx="12.5"
        ry="12.5"
        transform="rotate(90 12.5 106.5)"
        stroke="#98C379"
      />
      <ellipse
        in:fade={{ duration: 500, delay: 2500, easing: quintOut }}
        cx="12.5"
        cy="45.5"
        rx="12.5"
        ry="12.5"
        transform="rotate(90 12.5 45.5)"
        fill="#E06C75"
      />
      <circle
        in:fade={{ duration: 500, delay: 2500, easing: quintOut }}
        cx="12.5"
        cy="75.5"
        r="12.5"
        transform="rotate(90 12.5 75.5)"
        fill="#E5C07B"
      />
      <ellipse
        in:fade={{ duration: 500, delay: 2500, easing: quintOut }}
        cx="12.5"
        cy="106.5"
        rx="12.5"
        ry="12.5"
        transform="rotate(90 12.5 106.5)"
        fill="#98C379"
      />
    {/if}
  </svg>
{/if}
