<script lang="ts">
  import { onMount, setContext } from "svelte";
  import { fade } from "svelte/transition";
  import { apiToken } from "../store.js";
  import LoginError from "../components/LoginError.svelte";
  import Logo from "../components/Animated/Logo.svelte";
  import { navigate } from "svelte-routing";

  let ready = false;
  onMount(() => (ready = true));

  let rememberMe = true;
  let email = "";
  let password = "";

  let errorMessage = "";

  const errorMessageDisplay = (message) => {
    errorMessage = message;

    setTimeout(() => {
      errorMessage = "";
    }, 4000);
  };

  const login = async () => {
    const data = { email, password };
    console.log(`Remember ME: ${rememberMe}`);
    const resp = await fetch(`${BASE_URL}/v1/auth/login`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (resp.ok) {
      console.log("logged in!");
      const respObj = await resp.json();

      if (rememberMe) {
        localStorage.setItem("studious-api-key", respObj.token);
        apiToken.set(respObj.token);
        navigate("/");
      } else {
        sessionStorage.setItem("studious-api-key", respObj.token);
        apiToken.set(respObj.token);
        navigate("/");
      }
    } else if (resp.status === 404) {
      errorMessageDisplay(
        "Oops! It seems like we can't find you. Are you sure you've entered your email correctly?"
      );
    } else if (resp.status === 401) {
      errorMessageDisplay("The password you entered is incorrect. Try again?");
    } else {
      errorMessageDisplay(
        "Oops! Something went wrong, please try again later?"
      );
    }
  };

  const redirectRegister = () => {
    navigate("/register");
  };
</script>

<main>
  <!-- <img class="logo" src="/assets/Logo.svg" alt="Studious" /> -->
  <div
    class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full space-y-4">
      <div class="logo">
        <Logo condition={ready} notAnimated={false} />
      </div>
      {#if errorMessage.length > 0}
        <div transition:fade>
          <LoginError message={errorMessage} />
        </div>
      {/if}
      <button
        class="group relative w-full flex justify-center mt-2 py-2 px-4 border border-white hover:border-gray-400 bg-none text-sm font-medium rounded-md text-white body-background hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 transition-all"
      >
        <span class="absolute left-1 inset-y-0 flex items-center pl-3">
          <i class="fa fa-google" />
        </span>
        Continue With Google
      </button>
      <div class="separator"><span class="text-gray-300 text-xs">or</span></div>

      <form on:submit|preventDefault={login} class="mt-2 space-y-4" novalidate>
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email-address" class="sr-only">Email Address</label>
            <input
              id="email-address"
              name="email"
              type="email"
              autocomplete="email"
              bind:value={email}
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm transition-all"
              placeholder="Email Address"
            />
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              bind:value={password}
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm transition-all"
              placeholder="Password"
            />
          </div>
        </div>

        <div class="flex items-center justify-between space-x-4">
          <div class="flex items-center">
            <input
              id="remember_me"
              name="remember_me"
              type="checkbox"
              bind:checked={rememberMe}
              class="h-4 w-4 text-gray-600 focus:ring-gray-500 border-gray-300 rounded transition-all"
            />
            <label for="remember_me" class="ml-2 block text-sm text-gray-300">
              Remember me
            </label>
          </div>

          <div class="text-sm">
            <a class="font-medium text-gray-300 hover:text-gray-300">
              Forgot your password?
            </a>
          </div>
        </div>

        <div>
          <button
            type="submit"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 transition-all"
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg
                class="h-5 w-5 text-gray-500 group-hover:text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            Sign in
          </button>
        </div>
      </form>
      <p class="mt-2 text-center text-sm text-gray-500">
        Or
        <a
          on:click={redirectRegister}
          class="font-medium text-gray-300 cursor-pointer"
        >
          register for your 7-day free trial.
        </a>
      </p>
    </div>
  </div>
</main>

<style>
  main {
    text-align: center;
    margin: auto;
  }

  .logo {
    display: block;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
  }

  .separator {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .separator::before,
  .separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #d1d5db;
  }

  .separator:not(:empty)::before {
    margin-right: 0.4em;
  }

  .separator:not(:empty)::after {
    margin-left: 0.4em;
  }
</style>
